import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography, FormControlLabel, Checkbox } from "@mui/material";
import DateCalendarValue from './DateCalendarValue';
import dayjs from 'dayjs';
import './ScheduleService.css';
import axios from 'axios';

//Icons Import
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

// Spinner Import
import LoaderComponent from '../../../../components/LoaderComponent';

//Components Import
import WidgetHeader from '../../../../components/widget-layout/WidgetHeader';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ScheduleService = ({ data, onPrevious, onNext, onInputChange, widgetConfig }) => {
    const [loading, setLoading] = useState('');
    let tomorrowday = dayjs().add(2, 'day');
    if (tomorrowday.day() === 6) { // Saturday
        tomorrowday = tomorrowday.add(2, 'day'); // Move to Monday
    } else if (tomorrowday.day() === 0) { // Sunday
        tomorrowday = tomorrowday.add(1, 'day'); // Move to Monday
    }

    const tomorrow = tomorrowday;
    const [serviceDate, setServiceDate] = useState(tomorrow);
    const [timeslotsError, setTimeslotsError] = useState('');
    const [timeslotCheckbox, setTimeslotCheckbox] = useState(data.timeslotCheckbox || '');
    const [notes, setNotes] = useState(data.notes || '');
    const [message, setMessage] = useState(data.message || '');
    const [timeslotsDisabled, setTimeslotsDisabled] = useState(false);

    const timeslots = [
        '09:00 AM - 12:00 PM',
        '12:00 PM - 05:00 PM',
    ];
    const [selectedTimeslot, setSelectedTimeslot] = useState(data.selectedTimeslot || null);

    const navigate = useNavigate();

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setTimeslotCheckbox(1);
            setTimeslotsDisabled(true);
            // Add notes when checkbox is checked
            setNotes("None of these times work, Please call me");
            setTimeslotsError("");
            onInputChange({ ...data, timeslotCheckbox: true });
        } else {
            setTimeslotsDisabled(false);
            setTimeslotCheckbox('');
            // Remove notes when checkbox is unchecked
            setNotes('');
            onInputChange({ ...data, timeslotCheckbox: '' });
        }

    };

    const handleMessageChange = (event) => {
        const newMessage = event.target.value;
        setMessage(newMessage);
        onInputChange({ ...data, message: newMessage }); // Update message in parent data
    };

    const handleNext = async () => {
        if ((selectedTimeslot === '' || selectedTimeslot === null) && timeslotCheckbox === '') {
            setTimeslotsError("Please select a Time Slot");
            return false;
        }

        try {
            setLoading(true);
            let selectedStartTime = "";
            let selectedEndTime = "";
            let formattedDate = "";
            let scheduleFormatedDate = "";

            const userId = localStorage.getItem('user_id');
            const year = serviceDate.$y; // Full year (e.g., 2024)
            const month = String(serviceDate.$M + 1).padStart(2, '0'); // Month (0-11, padStart ensures 2-digit format)
            const day = String(serviceDate.$D).padStart(2, '0'); // Day of the month (1-31, padStart ensures 2-digit format)

            if (!timeslotCheckbox) {
                // Create the formatted date string "YYYY-MM-DD"
                formattedDate = `${year}-${month}-${day}`;
                scheduleFormatedDate = `${month}/${day}/${year}`;

                if (selectedTimeslot === "09:00 AM to 12:00 PM") {
                    selectedStartTime = "09:00:00";
                    selectedEndTime = "12:00:00";
                } else {
                    selectedStartTime = "12:00:00";
                    selectedEndTime = "17:00:00";
                }
            }

            const apiResponse = axios.post(`${apiBaseUrl}/api/update-job-schedule`, {

                jobId: userId,
                message: message,
                notes: notes,
                scheduleDate: formattedDate,
                startTime: selectedStartTime,
                endTime: selectedEndTime,
                timeSlot: selectedTimeslot,
                partnerID: widgetConfig.partnerID,
                partnerName: widgetConfig.partnerName,
                partnerEmail: widgetConfig.partnerEmail,
                partnerPhone: widgetConfig.partnerPhone,
                partnerAddress: widgetConfig.partnerAddress,
                partnerLogoURL: widgetConfig.partnerLogoURL,
                partnerURL: widgetConfig.partnerURL
            });

            localStorage.setItem('schedule_date', scheduleFormatedDate);
            localStorage.setItem('start_time', selectedStartTime);
            localStorage.setItem('end_time', selectedEndTime);
            localStorage.setItem('time_slot', selectedTimeslot);

            setLoading(true);
            // Introduce a delay of 2 seconds using setTimeout
            await new Promise(resolve => setTimeout(resolve, 2000));

            setLoading(false);

            if (!timeslotCheckbox) {
                localStorage.clear();
                const timer = setTimeout(() => {
                    window.parent.location.href = 'https://phoenix.moxieservices.com/thank-you/';
                }, process.env.REACT_APP_TIME_DELAY);
                //onNext();
            } else {
                localStorage.clear();
                navigate('/call-back-request');
            }
        } catch (error) {
            console.error('Error saving Property Details:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleServiceDateChange = (newDate) => {
        setServiceDate(newDate); // Update local state with the new date
        onInputChange({ ...data, serviceDate: newDate }); // Update serviceDate in parent data
    };

    const handleTimeslotClick = (timeslot) => {
        setSelectedTimeslot(timeslot);
        onInputChange({ ...data, selectedTimeslot: timeslot }); // Update selectedTimeslot in parent data
        setTimeslotsError("");
    };


    return (
        <div>
            {loading && (
                <LoaderComponent />
            )}
            <div style={{ opacity: loading ? 0.5 : 1 }}>
                <Box className='mainFrame'>
                    <Box className='frameHead'>
                        <Box className='frameNavigation'>
                            <WidgetHeader partnerName={widgetConfig.partnerName} partnerLogo={widgetConfig.partnerLogoLightURL} />
                        </Box>
                    </Box>
                    <Box className='frameBody'>
                        <Container className='' sx={{ paddingTop: '10px' }}>
                            <Typography className='noteText' sx={{}}>
                                In order to provide the best service, we ask that the decision maker for the home be present during the appointment. Please select a day and time that works best with your schedule.
                            </Typography>

                            <Box className="moxieSchedule" sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{

                                }}>
                                    {/* <Typography className='moxieAppHeading1 secondaryTitle scheduleTitle' sx={{ textAlign: 'left', fontSize: '10px', paddingLeft: '12px' }}>
                                        Schedule Date
                                    </Typography> */}


                                    <DateCalendarValue sx={{ width: '30px', height: '30px' }}

                                        serviceDate={serviceDate}
                                        setServiceDate={handleServiceDateChange}
                                        disabled={timeslotsDisabled || timeslotCheckbox}>
                                    </DateCalendarValue>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap', mt: 1 }}>
                                        <span className='required timeslotError'>{timeslotsError && '*'}</span>

                                        {/* {timeslots.map((timeslot, index) => (
                                            <Button className='scheduleBtn'
                                                key={index}
                                                variant="contained"
                                                onClick={() => handleTimeslotClick(timeslot)}
                                                sx={{
                                                    fontSize: '10px',
                                                    backgroundColor: selectedTimeslot === timeslot ? '#d3e2f0' : '#f2f2f2',
                                                    color: selectedTimeslot === timeslot ? '#3e87cb' : '#000000',
                                                    '&:hover': {
                                                        backgroundColor: selectedTimeslot === timeslot ? '#d3e2f0' : '#e0e0e0',
                                                    },
                                                }}
                                                disabled={timeslotsDisabled || timeslotCheckbox}
                                            >
                                                {timeslot}
                                            </Button>
                                        ))}  */}
                                        {timeslots.map((timeslot, index) => (
                                            <Button
                                                className={selectedTimeslot === timeslot ? 'scheduleBtnActive' : 'scheduleBtnInactive'}
                                                key={index}
                                                variant="contained"
                                                onClick={() => handleTimeslotClick(timeslot)}
                                                disabled={timeslotsDisabled || timeslotCheckbox}
                                            >
                                                {timeslot}
                                            </Button>
                                        ))}
                                    </Box>
                                    {/* {timeslotsError && (
                                        <Typography variant="body2" sx={{ color: 'red', textAlign: 'center', mt: 1, fontSize: '11px' }}>
                                            {timeslotsError}
                                        </Typography>
                                    )} */}

                                    {/* <Box className='bottom-border' /> */}
                                    <Box className='calendarBoxFields' sx={{ padding: '0px 8px 8px' }}>
                                        <FormControlLabel sx={{ marginLeft: '0px', marginRight: '0px' }}
                                            control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} checked={timeslotCheckbox} onChange={handleCheckboxChange} />}
                                            label={
                                                <Typography className='checkBoxLabel'>
                                                    None of these times work, Please call me
                                                </Typography>
                                            }
                                            value={timeslotCheckbox}
                                        />
                                        <TextField className='customInput textMessage noTimesWork'
                                            label={
                                                <Typography className='checkBoxLabel'>
                                                    Your Message
                                                </Typography>
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{ backgroundColor: '#ffffff', fontSize: '13px', marginTop: '10px' }}
                                            value={message}
                                            onChange={handleMessageChange}
                                            InputProps={{

                                                inputProps: {
                                                    maxLength: 250
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                        </Container>
                        <Box className='footerActions'>
                            <Box sx={{ mr: 1 }}>
                                <Button variant="outlined" className='secondaryBtn' onClick={onPrevious}><WestIcon /></Button>
                            </Box>
                            <Box>
                                <Button variant="contained" className='primaryBtn' onClick={handleNext} endIcon={<EastIcon />}>Next</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default ScheduleService;