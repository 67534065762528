import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './helpers/ProtectedRoute';
import Login from './pages/login/Login';
import Logout from './pages/Logout';

// Service Provider Routes
import SPDashboard from './pages/service-provider/dashboard/Dashboard';
import LeadsSales from './pages/service-provider/leads-sales/LeadsSales';
import Reports from './pages/service-provider/reports/Reports';
import ServiceAreas from './pages/service-provider/settings/service-areas/ServiceAreas';

// Common Routes
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import ChangePassword from './pages/change-password/ChangePassword';
import ResetPassword from './pages/reset-password/ResetPassword';
import Profile from './pages/profile/Profile';

// Static Pages Route
import About from './pages/static/About';
import Contact from './pages/static/Contact';
import Widget from './pages/service-provider/widget/Widget';
import NotInServiceArea from './pages/service-provider/widget/NotInServiceArea';
import CallBackRequest from './pages/service-provider/widget/CallBackRequest';

function App() {
  return (
    <div className="App height100">      
      <Routes className="height100">
        {/* Common Routes */}
        <Route path="/" index element={ <Widget /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/logout" element={ <Logout /> } />
        <Route path="/profile" element={ <ProtectedRoute><Profile /></ProtectedRoute> } />        
        <Route path="/forgot-password" element={ <ForgotPassword /> } />
        <Route path="/change-password" element={ <ProtectedRoute><ChangePassword /></ProtectedRoute> } />
        <Route path="/reset-password/:token" element={ <ProtectedRoute><ResetPassword /></ProtectedRoute>} />

        {/* Service Provider Routes */}
        <Route path="/service-provider" element={ <ProtectedRoute><SPDashboard /></ProtectedRoute> } />
        <Route path="/service-provider/dashboard" element={ <ProtectedRoute><SPDashboard /></ProtectedRoute> } />
        <Route path="/service-provider/service-areas" element={ <ProtectedRoute><ServiceAreas /></ProtectedRoute> } />
        <Route path="/service-provider/leads-sales" element={ <ProtectedRoute><LeadsSales /></ProtectedRoute> } />
        <Route path="/service-provider/reports" element={ <ProtectedRoute><Reports /></ProtectedRoute> } />
        
        {/* Static Pages Routes */}
        <Route path="/about-us" element={ <About /> } />
        <Route path="/contact-us" element={ <Contact /> } />
        <Route path="/widget" element={ <Widget /> } />
        <Route path="/not-in-service-area" element={ <NotInServiceArea /> } />
        <Route path="/call-back-request" element={ <CallBackRequest /> } />
      </Routes>
    </div>
  );
}

export default App;
