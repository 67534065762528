import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const RequiredTextField = ({ label, ...props }) => {
  return (
    <TextField
      label={
        <Typography>
          {label}
          <span className="required">*</span>
        </Typography>
      }
      {...props}
    />
  );
};

export { RequiredTextField };