import { React, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Box, Card, CardContent, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import LoginNav from '../../components/login-navbar/LoginNavbar';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ResetPassword = () => { 
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { token } = useParams(); // Access the token parameter
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${apiBaseUrl}/api/reset-password`, {
                token,
                password,
                password_confirmation: confirmPassword,
            });
            setMessage('Password reset successful');
            navigate('/login');  // Redirect to login page after successful reset
        } catch (error) {
            setMessage(error.response.data.message);
        }
    };

    return (
        <>
            <LoginNav />
            <div style={{ marginTop: '50px', display: 'grid', placeItems: 'center' }}>
                <Container maxWidth="sm">
                    <Card sx={{ maxWidth:600, margin: 'auto', padding: '20px'}}>
                        <CardContent>
                            <Box sx={{ margin: 8, textAlign: 'center' }}>
                                <Typography variant="h4" gutterBottom>
                                    Reset Password
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="New Password"
                                        variant="outlined"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        margin="normal"
                                    />
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="Confirm Password"
                                        variant="outlined"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        margin="normal"
                                    />
                                    <Button type="submit" variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </form>
                                { message && <Typography variant="body1">{message}</Typography> }
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        </>            
    );
};

export default ResetPassword