import React, { useState } from 'react';
import { Card, CardContent, TextField, Button, Typography, Container, Box } from '@mui/material';
import axios from 'axios';
import LoginNavbar from '../../components/login-navbar/LoginNavbar';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			await axios.post(`${apiBaseUrl}/api/forgot-password`, { email });
			setMessage('Password reset link sent successfully')
		} catch (error) {
			setMessage(error.response.data.message);
		}
	};

	return (
		<>
			<LoginNavbar />
			<div style={{ marginTop: '50px', display: 'grid', placeItems: 'center' }}>
				<Container maxWidth="sm">
					<Card sx={{ maxWidth:600, margin: 'auto', padding: '20px'}}>
						<CardContent>
							<Box sx={{marginTop: 8, textAlign: 'center'}}>
								<Typography variant="h4" gutterBottom>
									Forgot Password
								</Typography>
								<form onSubmit={handleSubmit}>
									<TextField
										fullWidth
										type="email"
										label="Email"
										variant="outlined"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										margin="normal"
									/>
									<Button type="submit" variant="contained" color="primary">
										Submit
									</Button>					
								</form>
								{message && <Typography variant="body1" style={{ color: '#d32f2f', fontSize: "0.75rem" }}>{message}</Typography>}
							</Box>
						</CardContent>
					</Card>
				</Container>
			</div>
		</>
	);
}

export default ForgotPassword;