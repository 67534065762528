// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, useMediaQuery } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import './LoginNav.css';

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// const LoginNav = () => {
// 	const location = useLocation();
// 	const isMobile = useMediaQuery('(max-width: 600px)');
// 	const navigate = useNavigate();

// 	const handleLogoClick = () => {
// 		const token = localStorage.getItem('accessToken');

// 		if (token) {
// 			navigate('/service-provider');
// 		} else {
// 			navigate('/login');
// 		}
// 	};

// 	const handleLogout = async () => {
// 		try {
// 			const token = localStorage.getItem('accessToken');

// 			await axios.post(`${apiBaseUrl}/api/logout`,
// 				{},
// 				{
// 					headers: {
// 						Authorization: `Bearer ${token}`,
// 					},
// 				}
// 			);

// 			// Removing the Acces Token from local storage
// 			localStorage.removeItem('accessToken');

// 			// Redirectng to login
// 			return <Navigate to='/login' />
// 		} catch (error) {
// 			console.log('Logout failed!', error);
// 		}
// 	};

// 	return (
// 		// <StyledAppBar position="static">
// 		<AppBar position="static" className="custom-appbar">
// 			<Toolbar>
// 				{isMobile ? (
// 					<IconButton edge="start" color="inherit" aria-label="menu">
// 						<MenuIcon />
// 					</IconButton>
// 				) : (
// 					<>
// 						<img src={require('../../assets/Logo-blue.png')} alt="Logo" className="logo" onClick={handleLogoClick} />
// 						<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>

// 						</Typography>
// 					</>
// 				)}
// 				{isMobile && (
// 					<>
// 						<Button sx={{ color: 'text.secondary', '&:hover': { color: '#ffffff' } }} component={Link} to="/" className={`menu-item ${location.pathnname === '/' ? 'active-link' : ''}`}>
// 							Home
// 						</Button>
// 						<Button sx={{ color: 'text.secondary', '&:hover': { color: '#ffffff' } }} component={Link} to="/about-us" className={`menu-item ${location.pathname === '/about-us' ? 'active-link' : ''}`} >
// 							About Us
// 						</Button>
// 						<Button sx={{ color: 'text.secondary', '&:hover': { color: '#ffffff' } }} component={Link} to="/contact-us" className={`menu-item ${location.pathname === '/contact-us' ? 'active-link' : ''}`}>
// 							Contact us
// 						</Button>
// 						<Button sx={{ color: 'text.secondary', '&:hover': { color: '#ffffff' } }} onClick={handleLogout} className={`menu-item`}>
// 							Logout
// 						</Button>
// 					</>
// 				)}
// 				{!isMobile && (
// 					<>
// 						<Button component={Link} to="/" className={`menu-item ${location.pathname === '/' ? 'active-link' : ''}`}>
// 							Home
// 						</Button>
// 						<Button component={Link} to="/about-us" className={`menu-item ${location.pathname === '/about-us' ? 'active-link' : ''}`}>
// 							About Us
// 						</Button>
// 						<Button component={Link} to="/contact-us" className={`menu-item ${location.pathname === '/contact-us' ? 'active-link' : ''}`}>
// 							Contact Us
// 						</Button>
// 						<Button onClick={handleLogout} className={`menu-item`}>
// 							Logout
// 						</Button>
// 					</>
// 				)}
// 			</Toolbar>
// 			{/* </StyledAppBar> */}
// 		</AppBar>
// 	);
// }

// export default LoginNav


import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
import { useMediaQuery } from '@mui/material';
import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
// import './LoginNav.css';

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const pages = [
	// {name: 'About Us', path: '/about-us'}, 
	{name: 'Contact Us', path: '/contact-us', icon: <PhoneIcon sx={{ color: 'inherit' }} />}, 
	// {name: 'Login', path: '/login'},
];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const LoginNavbar = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width: 600px)');

	const handleLogoClick = () => {
		const token = localStorage.getItem('accessToken');

		if (token) {
			navigate('/service-provider');
		} else {
			navigate('/login');
		}
	};

	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar position="static" sx={{backgroundColor: '#dff0ff'}}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					{/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
					{ !isMobile && (
					<img 
						src={require('../../assets/Logo-blue.png')}
						alt="Logo"
						sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, cursor: 'pointer' }}
					/> )}
					{/* <Typography
						variant="h6"
						noWrap
						component="a"
						href="#app-bar-with-responsive-menu"
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						LOGO
					</Typography> */}

					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="#45617c"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{pages.map((page) => (
								<MenuItem key={page.name} component={Link} to={page.path} onClick={handleCloseNavMenu}>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										{page.icon}
										<Typography textAlign="center" ml={1}>{page.name}</Typography>
									</Box>
								</MenuItem>
							))}
						</Menu>
					</Box>
					{/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
					{ isMobile && (
						<img 
							src={require('../../assets/Logo-blue.png')}
							alt="Logo"
							sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, cursor: 'pointer' }}
						/>
					)}
					{/* <Typography
						variant="h5"
						noWrap
						component="a"
						href="#app-bar-with-responsive-menu"
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						
					</Typography> */}
					
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'flex-end' } }}>
						{pages.map((page) => (
							<Button
								key={page.name}
								component={Link}
								to={page.path}
								onClick={handleCloseNavMenu}
								sx={{ my: 2, color: '#45617c', display: 'block' }}
							>
								{page.icon}
								{page.name}
							</Button>
						))}
					</Box>

					{/* <Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Open settings">
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: '45px' }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{settings.map((setting) => (
								<MenuItem key={setting} onClick={handleCloseUserMenu}>
									<Typography textAlign="center">{setting}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box> */}
				</Toolbar>
			</Container>
		</AppBar>
	);
}

export default LoginNavbar;
