import * as React from 'react';
import { Box } from "@mui/material";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import EventIcon from '@mui/icons-material/Event';

export default function DateCalendarValue({ serviceDate, setServiceDate ,disabled}) {
  let tomorrowday = dayjs().add(2, 'day');
  if (tomorrowday.day() === 6) { // Saturday
    tomorrowday = tomorrowday.add(2, 'day'); // Move to Monday
  } else if (tomorrowday.day() === 0) { // Sunday
    tomorrowday = tomorrowday.add(1, 'day'); // Move to Monday
  }

  const tomorrow = tomorrowday; // Calculate tomorrow's date
  
    

  const handleChange = (newValue) => {
    // Check if the selected date is a Saturday (6) or Sunday (0)
    const day = newValue.day();
    if (day === 0 || day === 6) {
        // Do not update serviceDate if it's Saturday or Sunday
        return;
    }
    
    // Update serviceDate with the selected date
    setServiceDate(newValue);
  };

  const shouldDisableDate = (date) => {
    const day = date.day();
    // Disable Saturdays (6) and Sundays (0)
    return day === 0 || day === 6;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="calendarBox">
      <DateCalendar
          value={serviceDate}
          onChange={handleChange}
          minDate={tomorrow}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
        />
      </Box>      
    </LocalizationProvider>
  );
}
