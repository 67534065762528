import React from 'react';
import SPTopNavbar from '../../../components/sp-top-navbar/SPTopNavbar';


const Dashboard = () => {
	return (
		<>
			<SPTopNavbar />
			<h2>Dashboard</h2>
		</>
		
	);
}
export default Dashboard;