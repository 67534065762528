import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import LoginNavbar from '../../components/login-navbar/LoginNavbar';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear the error message for the field
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      toast.error('Name is required', { autoClose: 3000 });
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      toast.error('Email is required', { autoClose: 3000 });
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
      toast.error('Invalid email format', { autoClose: 3000 });
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      toast.error('Message is required', { autoClose: 3000 });
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Form is valid, submit the data
      toast.success('Message Sent successfully', { autoClose: 3000 });
      // Reset the form
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    }
  };

  return (
    <>
      <LoginNavbar />
      <Container maxWidth="sm" sx={{ mt: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Your Name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email Address"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            id="message"
            name="message"
            label="Message"
            value={formData.message}
            onChange={handleChange}
            error={!!errors.message}
            helperText={errors.message}
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
            Submit
          </Button>
        </Box>
        
      </Container>
    </>
  );
}
