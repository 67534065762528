import LoginNavbar from '../../components/login-navbar/LoginNavbar';

const About = () => {
	return (
		<>
		<LoginNavbar />
		<h1>About US</h1>
		</>
		);
}
export default About;