import * as React from 'react';

//Gadgets Import
import { Box, Button, Typography, IconButton, Container } from "@mui/material";

//Icons Import
import EastIcon from '@mui/icons-material/East';

//Individucal Css Import
import '../get-started/GetStarted.css';

//Components Import
import WidgetHeader from '../../../../components/widget-layout/WidgetHeader';
import Footer from '../../../../components/widget-layout/WidgetFooter';

const GetStarted = ({ onNext }) => {
    const handleNext = () => {
        localStorage.clear();
        try {
            onNext();
        } catch (error) {
            console.error('Error saving step 1 data:', error);
        }
    };

    return (
        <Box className='mainFrame'>
                <Box className='frameHead frameHeadGetStarted'> 
                    <Box className='frameNavigation'>
                        <WidgetHeader />
                    </Box>
                </Box>
                <Box className='frameBody frameBodyGetStarted'>
                    <Container>
                        <Box className='getStartedTopSpacing'>
                            <Typography className='mainTitle'>
                                Premium Termite Service
                            </Typography> 
                            <Typography className='commonDescription'>
                            Our premium termite services require a FREE home inspection by one of our Termite Experts. Get started to schedule…
                            </Typography>
                        </Box>
                        <Box>
                            <Button variant="contained" className='primaryBtn getStartedBtn' endIcon={<EastIcon />} onClick={handleNext} >
                                Get Started
                            </Button>
                        </Box>
                    </Container>
                </Box>

                <Footer />
            </Box>
    );
};

export default GetStarted;