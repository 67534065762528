import axios, { Axios } from 'axios';
import { useNavigate } from 'react-router-dom';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('accessToken');

            await axios.post(`${apiBaseUrl}/api/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
                
            );

            // Removing the Acces Token from local storage
            localStorage.removeItem('accessToken');

            // Redirectng to login
            navigate('/login');
        } catch (error) {
            console.log('Logout failed!', error);
        }
    };

    return <button onClick={handleLogout}>Logout</button>
}

export default Logout;