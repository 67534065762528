import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, TextField, FormHelperText, Container, Select, MenuItem, FormControl, InputLabel, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { styled } from '@mui/material/styles';
import { RequiredTextField } from '../../../../components/RequiredTextField';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './PropertyDetails.css';

// for Auto Complete Address 
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

//Icons Import
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import AspectRatioRoundedIcon from '@mui/icons-material/AspectRatioRounded';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CityIcon from '@mui/icons-material/LocationCity';

// Spinner Import
import LoaderComponent from '../../../../components/LoaderComponent';

//Components Import
import WidgetHeader from '../../../../components/widget-layout/WidgetHeader';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const PropertyDetails = ({ data, onPrevious, onNext, onInputChange, widgetConfig }) => {
    const [loading, setLoading] = useState(true);
    const [stateNames, setStateNames] = useState([]);
    const [formData, setFormData] = useState({ ...data });

    // Form Errors
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [zipError, setZipError] = useState('');
    const [inspectionConsentCheckbox, setInspectionConsentCheckbox] = useState(data.inspectionConsentCheckbox || '');

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        onInputChange({ ...formData, [name]: value });
        setFormData({ ...formData, [name]: value });

        if (name === 'name' && value.trim() !== '') {
            setNameError('');
        }
        if (name === 'email' && value.trim() !== '') {
            setEmailError('');
        }
        if (name === 'phoneNumber' && value !== '') {
            setPhoneError('');
        }
        if (name === 'address' && value.trim() !== '') {
            setAddressError('');
        }
        if (name === 'city' && value.trim() !== '') {
            setCityError('');
        }
        if (name === 'state' && value !== '') {
            setStateError('');
        }
        if (name === 'zipCode' && value !== '') {
            setZipError('');
        }
    };

    const verifyServiceArea = async () => {        
        try {
            setLoading(true);

            const response = await axios.post(`${apiBaseUrl}/api/get-service-areas`, {
                zipcode: formData.zipCode
            });

            if (response.status !== 200) {
                throw new Error('Failed to fetch service areas');
            }

            const serviceArea = response.data;

            if (serviceArea.service) {
                return true; // Return true if service is available in the area
            } else {
                 axios.post(`${apiBaseUrl}/api/add-lead`, {
                    jobId: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '',
                    jobOwner: "7",
                    JobDescription: "Termites pest control services for a home",
                    serviceTypeId: "1007",
                    cost: "0",
                    imageurl: "https://d12tseod5rbpvn.cloudfront.net/InvImages/3e3e2ce5-56db-4399-ac53-c59738032e26.png,,,,",
                    msg: "The lead's service request is outside the service areas.",
                    city: formData.city,
                    state: formData.state,
                    zip: formData.zipCode,
                    email: formData.email,
                    phone: formData.phoneNumber,
                    ParentJobId: "",
                    UserId: "84",
                    property_type: formData.propertyType,
                    address: formData.address,
                    name: formData.name,
                    ScheduleDate: "",
                    StartTime: "",
                    EndTime: "",
                    inspectionConsent: formData.inspectionConsentCheckbox,
                    partnerID: widgetConfig.partnerID,
                    partnerName: widgetConfig.partnerName,
                    partnerEmail: widgetConfig.partnerEmail,
                    partnerPhone: widgetConfig.partnerPhone,
                    partnerAddress: widgetConfig.partnerAddress,
                    partnerLogoURL: widgetConfig.partnerLogoURL,
                    partnerLogoLightURL: widgetConfig.partnerLogoLightURL,
                    partnerURL: widgetConfig.partnerURL,
                    CompanyId: widgetConfig.CompanyId
                });

                setLoading(true);
                // Introduce a delay of 2 seconds using setTimeout
                await new Promise(resolve => setTimeout(resolve, 1000));

                setLoading(false);

                setFormData({ ...data, zipCode: '' }); // Reset zipCode in data
                localStorage.clear();
                navigate('/not-in-service-area');
                return false; // Return false if service is not available in the area
            }
        } catch (error) {
            console.error('Error fetching service areas:', error);

            return false; // Return false in case of an error
        } finally {
            setLoading(false); // Set loading to false in the finally block
        }
    };

    const validateFormData = () => {
        let validate = true;
        const { name, address, city, zipCode, state, phoneNumber, email } = formData;

        if (name.trim() === '') {
            setNameError('Please enter your name');
            validate = false;
        }
        if (address.trim() === '') {
            setAddressError('Please enter your address');
            validate = false;
        }
        if (city.trim() === '') {
            setCityError('Please enter your city');
            validate = false;
        }
        if (zipCode === '') {
            setZipError(' ');
            validate = false;
        } else if (zipCode.length !== 5) {
            setZipError('Invalid zip code');
            validate = false;
        }
        if (state === '') {
            setStateError(' ');
            validate = false;
        }
        if (phoneNumber === '') {
            setPhoneError('Please enter your phone number');
            validate = false;
        } else if (phoneNumber.length !== 10) {
            setPhoneError('Invalid phone number');
            validate = false;
        }
        if (email.trim() === '') {
            setEmailError('Please enter your email');
            validate = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Invalid email format');
            validate = false;
        }

        return validate;
    };

    const handleNext = async () => {

        if (validateFormData()) {
            const isServiceAreaValid = await verifyServiceArea();
            if (isServiceAreaValid) {
                try {
                    setLoading(true);
                    const apiResponse = await axios.post(`${apiBaseUrl}/api/add-job`, {
                        jobId: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '',
                        jobOwner: "7",
                        JobDescription: "Termites pest control services for a home",
                        serviceTypeId: "1007",
                        cost: "0",
                        imageurl: "https://d12tseod5rbpvn.cloudfront.net/InvImages/3e3e2ce5-56db-4399-ac53-c59738032e26.png,,,,",
                        msg: "The lead's service request is outside the service areas.",
                        city: formData.city,
                        state: formData.state,
                        zip: formData.zipCode,
                        email: formData.email,
                        phone: formData.phoneNumber,
                        ParentJobId: "",
                        UserId: "84",
                        property_type: formData.propertyType,
                        address: formData.address,
                        name: formData.name,
                        ScheduleDate: "",
                        StartTime: "",
                        EndTime: "",
                        inspectionConsent: formData.inspectionConsentCheckbox,
                        partnerID: widgetConfig.partnerID,
                        partnerName: widgetConfig.partnerName,
                        partnerEmail: widgetConfig.partnerEmail,
                        partnerPhone: widgetConfig.partnerPhone,
                        partnerAddress: widgetConfig.partnerAddress,
                        partnerLogoURL: widgetConfig.partnerLogoURL,
                        partnerLogoLightURL: widgetConfig.partnerLogoLightURL,
                        partnerURL: widgetConfig.partnerURL,
                        CompanyId: widgetConfig.CompanyId
                    });
                    const userId = apiResponse.data.id;

                    // Save user ID to localStorage
                    localStorage.setItem('user_id', userId);
                    onNext();
                } catch (error) {
                    console.error('Error saving Property Details:', error);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const PropertyButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#d8e7f5',
            color: '#3e87cb',
        },
    }));

    const { homeButtonActive, businessButtonActive } = data;

    const handleButtonClick = (button, propertyType) => {
        if (button === 'homeButton') {
            onInputChange({
                homeButtonActive: true,
                businessButtonActive: false,
                propertyType: "Residential",
            });
            setFormData({
                ...formData,
                propertyType: "Residential",
                homeButtonActive: true,
                businessButtonActive: false,
            });
        } else {
            onInputChange({
                homeButtonActive: false,
                businessButtonActive: true,
                propertyType: "Commercial",
            });
            setFormData({
                ...formData,
                propertyType: "Commercial",
                homeButtonActive: false,
                businessButtonActive: true,
            });
        }    
    };

    const handleInspectionConsentCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setInspectionConsentCheckbox(1);
            onInputChange({ ...data, inspectionConsentCheckbox: true });
            setFormData({ ...formData, inspectionConsentCheckbox: true });
        } else {
            setInspectionConsentCheckbox('');
            onInputChange({ ...data, inspectionConsentCheckbox: false });
            setFormData({ ...formData, inspectionConsentCheckbox: false });
        }
    };

    const validateEmail = () => {
        if (!/^\S+@\S+\.\S+$/.test(data.email)) {
            setEmailError('Invalid email format');
        }
    }

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/get-states`);
                setStateNames(response.data.map(state => state.StateName)); // Update states after data is fetched
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    const getCityState = async () => {
        setLoading(true);
        const response = await axios.post(`${apiBaseUrl}/api/get-city-state`, {
            zipcode: formData.zipCode
        }); 
        
        if (response.data.service) {
            onInputChange({ city: response.data.city, });
            setFormData({
                ...formData,
                city: response.data.city,
                state: response.data.state,
            });
            setFormData({ ...data, 
                city: response.data.city,
                state: response.data.state });
            setLoading(false);
            setZipError('');
        } else {
            setFormData({
                ...formData,
                city: '',
               
            });
            setFormData({ ...data, 
                city: '',
             });
            setLoading(false);
        }
       
    };
// Function to populate state and city
    return (
        <div>
            {loading && (
                <LoaderComponent />
            )}
            <div style={{ opacity: loading ? 0.5 : 1 }}>
                <Box className='mainFrame'>
                    <ToastContainer />
                    <Box className='frameHead'>
                        <Box className='frameNavigation'>
                            <WidgetHeader partnerName={widgetConfig.partnerName} partnerLogo={widgetConfig.partnerLogoLightURL} />
                        </Box>
                    </Box>
                    <Box className='frameBody'>
                        <Container>
                            <Typography className='moxieAppHeading secondaryTitle' sx={{ textDecoration: 'underline' }}>
                                Tell Us About Your Property
                            </Typography>
                            <Box className='topTabNavigation'>
                                <Box className='tabButton'>
                                    <PropertyButton
                                        className={`tabAction ${homeButtonActive ? 'tabButtonActive' : 'tabButtonInactive'}`}
                                        variant="contained"
                                        startIcon={<HomeIcon />}                                        
                                        onClick={() => handleButtonClick('homeButton', 'Residential')}
                                    >
                                        Home
                                    </PropertyButton>
                                </Box>
                                <Box className='tabButton'>
                                    <PropertyButton
                                        className={`tabAction ${businessButtonActive ? 'tabButtonActive' : 'tabButtonInactive'}`}
                                        variant="contained"
                                        startIcon={<BusinessIcon />}                                        
                                        onClick={() => handleButtonClick('businessButton', 'Commercial')}
                                    >
                                        Business
                                    </PropertyButton>
                                </Box>
                            </Box>
                            <Box>
                                <Box className="propDetails">
                                    <input type="hidden" name="propertyType" value={data.propertyType} />

                                    <TextField fullWidth
                                        className='customInput' id="outlined-basic" label={
                                            <Typography>
                                                Name
                                                <span className="required">*</span>
                                            </Typography>
                                        } variant="outlined" name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <PersonIcon style={{ color: '#666' }} />
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                onInput: (event) => {
                                                    event.target.value = event.target.value.replace(/[^a-zA-z0-9 ]/g, '');
                                                }
                                            }
                                        }}
                                        error={Boolean(nameError)}
                                    />
                                    <RequiredTextField
                                        fullWidth
                                        variant="outlined"
                                        className='customInput'
                                        sx={{ marginBottom: '20px' }}
                                        label="Phone Number"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LocalPhoneRoundedIcon className='icon' style={{ color: '#666' }} />
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                maxLength: 10,
                                                onInput: (event) => {
                                                    event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                                }
                                            }
                                        }}
                                        error={Boolean(phoneError)}
                                    />
                                    <RequiredTextField
                                        fullWidth
                                        className='customInput'
                                        variant="outlined"
                                        sx={{ marginBottom: '20px' }}
                                        label="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        onBlur={validateEmail}

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <MailRoundedIcon className='icon' style={{ color: '#666' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(emailError)}
                                    />
                                    <RequiredTextField
                                        fullWidth
                                        className='customInput'
                                        sx={{ marginBottom: '20px' }}
                                        label="Address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <LocationOnIcon className='icon' style={{ color: '#666' }} />
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                onInput: (event) => {
                                                    event.target.value = event.target.value.replace(/[^#,.0-9a-zA-Z\s-]/g, '');
                                                }
                                            }
                                        }}
                                        error={Boolean(addressError)}
                                    />
                                    <RequiredTextField
                                        fullWidth
                                        className='customInput'
                                        variant="outlined"
                                        sx={{ marginBottom: '20px' }}
                                        label="City"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <CityIcon className='icon' style={{ color: '#666' }} />
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                onInput: (event) => {
                                                    event.target.value = event.target.value.replace(/[^a-zA-z ]/g, '');
                                                }
                                            }
                                        }}
                                        error={Boolean(cityError)}
                                    />                                   
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth className="customInput commonInputSelect">
                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                <Select sx={{ fontSize: '13px' }}
                                                    name="state"
                                                    value={formData.state}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{
                                                        'aria-label': ' '
                                                    }}
                                                    variant="outlined"
                                                    error={Boolean(stateError)}
                                                >
                                                    {stateNames.length > 0 && stateNames.map((statename) => (
                                                        <MenuItem key={statename} value={statename}>
                                                            {statename}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {stateError && <FormHelperText style={{ color: '#d32f2f' }} className='commonSelectError'>{stateError}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                            fullWidth
                                            id="outlined-uncontrolled"
                                            className='customInput'
                                            //className={zipError ? 'zipCodeError' : 'customInput'}
                                            variant="outlined"
                                            label={
                                                <Typography>
                                                    Zip Code
                                                    <span className="required">*</span>
                                                </Typography>
                                            }
                                            name="zipCode"
                                            value={formData.zipCode}
                                            onChange={handleChange}
                                            onBlur={getCityState}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <AspectRatioRoundedIcon className='icon' style={{ color: '#666' }} />
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    maxLength: 5,
                                                    onInput: (event) => {
                                                        event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                                    }
                                                }
                                            }}
                                            error={Boolean(zipError)}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Box className="inspectionCost">
                                        <FormControlLabel sx={{ marginLeft: '0px', marginRight: '0px' }}
                                            control={<Checkbox name="inspectionConsent" className='inspectionCostCheckbox' sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }} checked={inspectionConsentCheckbox} onChange={handleInspectionConsentCheckboxChange}  />}
                                            label={
                                                <Typography className='inspectionCostCheckboxLabel'>
                                                    This inspection is for the sale/refinance of the property (additional filing costs may apply)
                                                </Typography>
                                            }
                                            value={formData.inspectionConsentCheckbox}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                        <Box className='footerActions'>
                            <Box sx={{ mr: 1 }} style={{display:"none"}}>
                                <Button variant="outlined" className='secondaryBtn' onClick={onPrevious}><WestIcon /></Button>
                            </Box>
                            <Box>
                                <Button variant="contained" className='primaryBtn' onClick={handleNext} endIcon={<EastIcon />}>Next</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default PropertyDetails;