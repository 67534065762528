import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GetStarted from '../widget/get-started/GetStarted';
import PropertyDetails from './property-details/PropertyDetails';
import ScheduleService from './schedule-service/ScheduleService';
import InspectionScheduled from './inspection-scheduled/InspectionScheduled';
import axios from 'axios';

// For ScheduleService
import dayjs from 'dayjs';

const apibaseURL = process.env.REACT_APP_API_BASE_URL;

// Function to calculate tomorrow's date excluding weekends
function calculateTomorrow() {
    let tomorrow = dayjs().add(2, 'day'); // Start with tomorrow's date

    // Loop until we get a weekday (Monday to Friday)
    while (tomorrow.day() === 0 || tomorrow.day() === 6) {
        tomorrow = tomorrow.add(1, 'day');
    }

    return tomorrow;
}

let tomorrowday = calculateTomorrow();
if (tomorrowday.day() === 6) { // Saturday
    tomorrowday = tomorrowday.add(2, 'day'); // Move to Monday
} else if (tomorrowday.day() === 0) { // Sunday
    tomorrowday = tomorrowday.add(1, 'day'); // Move to Monday
}

const tomorrow = tomorrowday
//const tomorrow = calculateTomorrow(); // Calculate tomorrow's date

const useQuery = () => new URLSearchParams(useLocation().search);

const Widget = () => {
    const [widgetConfig, setWidgetConfig] = useState({});
    const query = useQuery();
    const partnerId = query.get('partnerId');

    // Fetching the Widget config from database
    const getWidgetConfig = async () => {
        try {
            const response = await axios.get(`${apibaseURL}/api/get-widget-config/${partnerId}`)
            // console.log("Widget Response: ",response);
            if (response.data) {
                const config = {
                    themeColor: response.data.BackgroundColor,
                    filledButtonTextColor: response.data.FilledButtonTextColor,
                    partnerLogoURL: 'https://filesdemo.fingateway.com/MoxieImages/' + response.data.Logo,
                    partnerLogoLightURL: 'https://filesdemo.fingateway.com/MoxieImages/' + response.data.PartnerLogoLight,
                    partnerID: response.data.PartnerID,
                    partnerName: response.data.PartnerName,
                    partnerEmail: response.data.PartnerEmail,
                    partnerPhone: response.data.PartnerPhone,
                    partnerAddress: response.data.PartnerAddress,
                    CompanyId: response.data.CompanyId,
                    partnerURL: response.data.CompanyURL
                };
                setWidgetConfig(config); // Update state with the fetched config
            } else {
                console.error("Unexpected response structure:", response);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const hexToHSL = (hex) => {
        // Convert hex to RGB
        let r = parseInt(hex.slice(1, 3), 16) / 255;
        let g = parseInt(hex.slice(3, 5), 16) / 255;
        let b = parseInt(hex.slice(5, 7), 16) / 255;

        // Find the maximum and minimum values to get luminance
        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        h = Math.round(h * 360);
        s = Math.round(s * 100);
        l = Math.round(l * 100);

        // Make the color very light by increasing lightness
        l = 70; // adjust this value for desired lightness

        return `hsl(${h}, ${s}%, ${l}%)`;
    }

    // Fetch the widget config when the component mounts or partnerId changes
    useEffect(() => {
        if (partnerId) {
            getWidgetConfig();
        }
    }, [partnerId]);

    useEffect(() => {
        if (Object.keys(widgetConfig).length > 0) {
            // Update CSS variables dynamically
            document.documentElement.style.setProperty('--themeColor', widgetConfig.themeColor || '#3e87cb');

            document.documentElement.style.setProperty('--filledButtonTextColor', widgetConfig.filledButtonTextColor || '#ffffff');
            
            document.documentElement.style.setProperty('--selectionButtonBGColor', widgetConfig.themeColor ? hexToHSL(widgetConfig.themeColor) : hexToHSL('#3e87cb'));
        }
    }, [widgetConfig]);

    const [currentStep, setCurrentStep] = useState(2);

    const initialPropertyDetailsState = {
        name: '',
        address: '',
        city: '',
        state: 'Arizona',
        zipCode: '',
        propertyType: 'Residential',
        email: '',
        phoneNumber: '',
        homeButtonActive: true,
        businessButtonActive: false,
        inspectionConsentCheckbox: '',
    };

    const initialScheduleServiceState = {
        serviceDate: tomorrow,
        selectedTimeslot: '',
        timeslotCheckbox: '',
        notes: '',
        message: '',
    };

    const [formData, setFormData] = useState({
        propertyDetails: initialPropertyDetailsState,
        scheduleService: initialScheduleServiceState,
    });

    const handleNext = () => {
        setCurrentStep(prevStep => prevStep + 1);
    }

    const handlePrevious = () => {
        setCurrentStep(prevStep => prevStep - 1);
    }

    const handleInputChange = (section, data) => {
        setFormData((prevState) => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                ...data,
            },
        }));
    };

    const handleSubmit = () => {
        console.log('Form Submitted', formData);
    }

    return (
        <div>
            {currentStep === 1 && <GetStarted
                onNext={handleNext}
            />}
            {currentStep === 2 && <PropertyDetails
                data={formData.propertyDetails}
                onPrevious={handlePrevious}
                onNext={handleNext}
                onInputChange={(data) => handleInputChange('propertyDetails', data)}
                widgetConfig={widgetConfig} // Passing widgetConfig as prop
            />}
            {currentStep === 3 && <ScheduleService
                data={formData.scheduleService}
                onPrevious={handlePrevious}
                onNext={handleNext}
                onInputChange={(data) => handleInputChange('scheduleService', data)}
                widgetConfig={widgetConfig} // Passing widgetConfig as prop
            />
            }
            {currentStep === 4 && <InspectionScheduled
                data={formData.scheduleService}
                onPrevious={handlePrevious}
                onSubmit={handleSubmit}
                widgetConfig={widgetConfig} // Passing widgetConfig as prop
            />
            }
        </div>
    );
}

export default Widget;