import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { Card, CardContent, TextField, Button, Link, FormControlLabel, Checkbox, IconButton, InputAdornment } from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import LoginNavbar from '../../components/login-navbar/LoginNavbar';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [errors, setErrors] = useState([]);
	const navigate = useNavigate();

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	}

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	}

	const handleShowPassword = (event) => {
		setShowPassword(!showPassword);
	}

	const handleRememberMeChange = (event) => {
		setRememberMe(event.target.checked);
	}

	const validateCredentials = (email, password) => {
		const errors = [];
		if (!email || !email.trim()) {
			errors.push("Email is required");
		}  
		
		if(!/^\S+@\S+\.\S+$/.test(email)) {
			errors.push("Invalid email format");
		}

		if(!password) {
			errors.push('Password is required');
		}

		if (password.length < 8) {
			errors.push('Password must be atleast 8 characters long');
		}

		return errors;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		
		setErrors([]); // Clearing previous errors

		const validationErrors = validateCredentials(email, password);

		if (validationErrors.length > 0) {
			setErrors(validationErrors);
			return;
		}

		try {
	     	const response = await axios.post(`${apiBaseUrl}/api/login`, {
	      		email,
	        	password,
				remember: rememberMe,
	      	});

			const accessToken = response.data.accessToken;

            // Store the access token (e.g., localStorage)
            localStorage.setItem('accessToken', accessToken);

	      	// Redirecting to Dashboard
	     	navigate('/service-provider/dashboard');

	    } catch (error) {
			if(error.response && error.response.data && error.response.data.message) {
				setErrors([error.response.data.message]);				
			} else {
				console.error('There was an error logging in!', error);
				setErrors(['There was an error logging in. Please try again.']);
			}	      	
	    }
	};

	return (
		<>
			<LoginNavbar />
			<div style={{ marginTop: '50px', display: 'grid', placeItems: 'center' }}>
				<Card sx={{ maxWidth:400, margin: 'auto', padding: '20px'}}>
					<CardContent>
						<form onSubmit={ handleSubmit }>
							<TextField
								label="Email"
								variant="outlined"
								value={ email }
								onChange={ handleEmailChange }
								margin="normal"
								fullWidth								
								type="email"
								error={errors.some((error) => error.toLowerCase().includes('email'))}
								helperText={errors.find((error) => error.toLowerCase().includes('email'))}
							/>
							<TextField
								label="Password"
								variant="outlined"
								value={ password }
								onChange={ handlePasswordChange }
								margin="normal"
								fullWidth								
								type={ showPassword ? 'text' : 'password' }
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={ handleShowPassword }>
												{ showPassword ? <VisibilityOff /> : <Visibility /> }
											</IconButton>
										</InputAdornment>
									),
								}}
								error={errors.some((error) => error.toLowerCase().includes('password'))}
								helperText={errors.find((error) => error.toLowerCase().includes('password'))}
							/>
							<FormControlLabel
								control={<Checkbox checked={ rememberMe } onChange={ handleRememberMeChange } />}
								label="Remember Me"
							/>
							{errors.length > 0 && errors[0] === "Invalid credentials. Please try again!" && (
							<div style={{ color: '#d32f2f', fontSize: "0.75rem", marginBottom: '10px' }}>
								{errors[0]}
							</div>
							)}
							<Button variant="contained" type="submit" color="primary" fullWidth>
								Login
							</Button>
							<Link component={RouterLink} to="/forgot-password" variant="body2">
								Forgot password?
							</Link>
						</form>
					</CardContent>
				</Card>
			</div>
		</>
	);
};

export default Login
