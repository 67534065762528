import React, { useState, useEffect } from 'react';
import loaderGif from '../assets/loader.gif'; // Adjust the path to your loader.gif
import '../App.css'; 

const LoaderComponent = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {        
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000); 

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {loading && (
                <div className="loaderOverlay" aria-label="loading">
                    {/* <img src={loaderGif} alt="Loading..." height="100" width="100" /> */}
                    <div className="loader"></div> {/* CSS Loader */}
                </div>
            )}
        </div>
    );
};

export default LoaderComponent;
