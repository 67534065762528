import * as React from 'react';
import { Box, Typography, Container } from "@mui/material";

//Individucal Css Import
import '../widget-layout/WidgetFooter.css';

const Footer = () => {
    return (
        <Container sx={{ }} className='footerContent'>
            <Typography variant="body1" noWrap component="div" sx={{ fontSize: '10px', display: 'flex', 
            justifyContent: 'center', alignItems: 'center'}}>
                Powered By Tempo
            </Typography>
        </Container>
    );
};

export default Footer;